.container {
  width: min(100%, 1440px);
  padding: 30px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbarButtons{
    display: flex;
    align-items: center;
    gap: 40px;
}

@media (max-width:1023px) {
  .container {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
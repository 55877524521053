.App {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.alignFull {
  width: 100%;
  height: 100%;
}

.objectCenter {
  transform: translate(-50%, -50%);
}

.desktopOnly {
  display: none;
}

.mobileOnly {
  display: flex;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.font_bold_24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.font_semibold_52_24 {
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  letter-spacing: -1.04px;
}

.font_semibold_32_24 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.64px;
}

.font_semibold_24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.font_semibold_18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}

.font_semibold_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.font_semibold_13 {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}

.font_medium_21 {
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 29.4px */
  letter-spacing: -0.42px;
}

.font_normal_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}

.font_normal_13 {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}

.font_normal_10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 11px */
  letter-spacing: -0.2px;
}

@media (min-width: 1024px) {
  .desktopOnly {
    display: flex;
  }

  .mobileOnly {
    display: none;
  }
}

@media (max-width: 1023px) {
  .font_semibold_52_24 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.48px;
  }
  .font_semibold_32_24 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
  }
}

.sectionWhite {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: #161616;
}

.sectionBlack {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0d0d;
  color: #f6f6f6;
}

.coverContainer {
  width: min(100%, 14400px);
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: min(100%, 1440px);
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storiesCarousel {
  width: 100%;
  margin-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  padding: 0 20px;
  justify-content: center;
}

.storiesCarousel > div {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  border-radius: 72px;
  border: 3px solid #f78a8a;
  overflow: hidden;
}

.storiesCarousel img {
  width: fit-content;
  height: fit-content;
}

.listContainer {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 60px;
  column-gap: 26px;
}

.comingSoonCarousel {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 26px;
  scrollbar-width: none;
  margin-top: 32px;
}

@media (max-width: 1023px) {
  .storiesCarousel {
    margin-top: 34px;
    gap: 16px;
    justify-content: left;
  }

  .container {
    padding: 40px 0px;
  }

  .newArrivalCarousel {
    padding: 0 20px;
  }

  .listContainer {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 60px;
    column-gap: 26px;
    padding: 0 20px;
  }

  .comingSoonCarousel {
    padding: 0 20px;
  }
}

@media (max-width: 640px) {
  .listContainer {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 26px;
  }
}

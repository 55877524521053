.sectionBlackDark {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0d0d;
  color: #f6f6f6;
}

.container {
  width: min(100%, 1440px);
  padding: 60px 100px;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1023px) {
  .container {
    padding: 40px 20px;
  }
}

.sectionWhite {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: #161616;
}
.sectionBlack {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111;
  color: #f6f6f6;
}
.sectionBlackDark {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0d0d;
  color: #f6f6f6;
}
.container {
  width: min(100%, 1440px);
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.collectionsContainer {
  width: min(100%, 1440px);
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
}

.collectionsListContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 60px;
  column-gap: 30px;
  margin-top: 32px;
}

.storiesContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}

.story {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.storyImageContainer {
  width: 72px;
  height: 72px;
  border-radius: 72px;
  border: 3px solid #f78a8a;
  overflow: hidden;
}

.storyImageContainer img {
  width: fit-content;
  height: fit-content;
}

.coverCarousel {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 30px;
  scrollbar-width: none;
  margin-top: 40px;
}

.coverCarousel div {
  width: 300px;
  flex-shrink: 0;
}

.coverCarousel img {
  width: 300px;
  height: 390px;
  object-fit: cover;
}

.newArrivalCarousel {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 26px;
  scrollbar-width: none;
  margin-top: 32px;
}

.ownerVideosCarousel {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 20px;
  scrollbar-width: none;
}

@media (max-width: 1023px) {
  .container {
    padding: 40px 0px;
  }

  .collectionsContainer {
    padding: 40px 20px;
  }

  .collectionsListContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 30px;
    margin-top: 32px;
  }

  .storiesContainer {
    gap: 16px;
  }

  .coverCarousel {
    margin-top: 24px;
  }

  .newArrivalCarousel {
    padding: 0 20px;
  }

  .ownerVideosCarousel {
    padding: 0 20px;
  }

  /* .newArrivalCarousel > div:first-child {
    margin-left: 20px;
  }

  .newArrivalCarousel > div:last-child {
    margin-right: 20px;
  } */
}

@media (max-width: 640px) {
  .collectionsListContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
    column-gap: 30px;
    margin-top: 32px;
  }
}
